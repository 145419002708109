<template>
  <div>
    <BCard>
      <BRow class="pl-1">
        <div class="text-black text-2xl font-semibold">
          Monitoring Order
        </div>
      </BRow>

      <BRow class="mb-2">
        <b-tabs
          pills
          card
          active-nav-item-class="!rounded-full !text-white !font-medium"
          nav-class="gap-8 !font-medium"
        >
          <b-tab
            title="Paksa Delivered"
            active
            title-item-class="!rounded-full !border border-[#f95031]"
            title-link-class="!text-[#f95031]"
            @click="forceDelivered = true, forceRTS = false"
          >
            <ForceDelivered v-if="forceDelivered" />
          </b-tab>
          <b-tab
            title="Paksa RTS"
            title-item-class="!rounded-full !border border-[#f95031]"
            title-link-class="!text-[#f95031]"
            @click="forceRTS = true, forceDelivered = false"
          >
            <ForceRTS v-if="forceRTS" />
          </b-tab>
        </b-tabs>
      </BRow>
    </BCard>
  </div>
</template>

<script>
import ForceDelivered from './ForceDelivered.vue'
import ForceRTS from './ForceRTS.vue'

export default {
  name: 'MonitoringOrder',
  components: {
    ForceDelivered,
    ForceRTS,
  },
  data() {
    return {
      forceDelivered: true,
      forceRTS: false,
    }
  },
}
</script>
